import { render, staticRenderFns } from "./BnoScreen.vue?vue&type=template&id=574660a4&scoped=true"
import script from "./BnoScreen.vue?vue&type=script&lang=js"
export * from "./BnoScreen.vue?vue&type=script&lang=js"
import style0 from "./BnoScreen.vue?vue&type=style&index=0&id=574660a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574660a4",
  null
  
)

export default component.exports